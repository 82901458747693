import { ROUTE_NAMES } from "@/router";
export default {
    install(Vue) {
        Vue.prototype.$routeNames = ROUTE_NAMES;
        Vue.prototype.$env = {
            DOMAIN_SERVER_ENV: process.env.DOMAIN_SERVER_ENV,
            DOMAIN_APP_ENV: process.env.DOMAIN_APP_ENV
        };
    }
};
