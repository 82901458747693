<template>
  <div class="form">
    <div class="form__item">
      <CustomInput v-model="formTitle" label="Заголовок"/>
    </div>
    <div class="form__item">
      <CustomInput v-model="formCode" label="Ключ"/>
    </div>
    <div class="form__item">
      <SwitchComp v-model="formPin" label="Закрепить"/>
    </div>
    <div class="form__item">
      <NumberInput v-model="formOrder" label="Порядок"/>
    </div>
    <div class="form__item">
      <SelectComp
        :value="getForm.config.mainField"
        :options="fieldsOptions"
        label="Главное поле"
        @change="(v) => setConfigMainField(v)"
      />
    </div>
    <div class="form__item">
      <SwitchComp
        :value="getForm.config.multi"
        label="Мультирежим"
        @change="(v) => setConfigMulti(v)"
      />
    </div>
    <div class="form__item form__item--full">
      <LabelPanel label="Поля">
        <div v-if="formFields.length">
          <Draggable
            v-model="orderFields"
            group="entityFields"
            handle=".moveAction"
            class="form__fields"
          >
            <div
              v-for="(field, index) in formFields"
              :key="index"
              class="form__field"
              :class="{ 'form__field--removed': field.deleted }"
            >
              <BadgeIcon :icon="getTypeField(field.type).icon" :type="getTypeField(field.type).color"/>
              <CustomInput
                :value="field.title"
                @input="(title) => editTitleCustomField({ title, index })"
                size="mini"
                label="Название"
              />
              <CustomInput
                :value="field.code"
                @input="(code) => editCodeCustomField({ code, index })"
                size="mini"
                label="Код"
              />
              <SwitchComp
                :value="field.required"
                @change="(value) => editFieldCustomField({ value, field: 'required', index })"
                label="Обязательный"
              />
              <SwitchComp
                :value="field.unique"
                @change="(value) => editFieldCustomField({ value, field: 'unique', index })"
                label="Уникальный"
              />
              <SwitchComp
                :value="field.pin"
                @change="(value) => editFieldCustomField({ value, field: 'pin', index })"
                label="Закрепить"
              />
              <SwitchComp
                :value="field.hidden"
                @change="(value) => editFieldCustomField({ value, field: 'hidden', index })"
                label="Скрыто"
              />
              <div class="form__field__nav">
                <CircleBtn class="moveAction" icon="rank"/>
                <CircleBtn @click="() => openFieldConfigModal({ field, index })" icon="cog"/>
                <CircleBtn @click="() => removeCustomField({ field, index })" icon="trash" type="error"/>
              </div>
            </div>
          </Draggable>
        </div>
        <AddField @add="(t) => addCustomField(t)"/>
      </LabelPanel>
    </div>
    <div class="form__item form__item--full">
      <CustomBtn @click="() => saveHandler()">Сохранить</CustomBtn>
    </div>
  </div>
</template>

<script>
import Draggable from 'vuedraggable';
import { mapActions, mapMutations, mapGetters } from "vuex";
import { namedListTypeField } from "@/const";
import { fieldConfigModal } from "@/mixins/modals";

import CustomInput from "../ui/Input";
import CustomBtn from "../ui/Btn";
import SwitchComp from "../ui/Switch";
import NumberInput from "../ui/NumberInput";
import LabelPanel from "../ui/LabelPanel";
import AddField from "./../ui/AddField.vue";
import BadgeIcon from "./../ui/BadgeIcon.vue";
import CircleBtn from "./../ui/CircleBtn.vue";
import SelectComp from "./../ui/Select.vue";

const compField = (field) => ({
  get() {
    return this.getField(field)
  },
  set(value) {
    this.setField({field, value})
  }
})

export default {
  name: 'EntityForm',
  components: {
    CustomInput,
    CustomBtn,
    SwitchComp,
    SelectComp,
    NumberInput,
    LabelPanel,
    AddField,
    BadgeIcon,
    CircleBtn,
    Draggable
  },
  mixins: [fieldConfigModal],
  props: {
    mode: {
      type: String,
      default: 'create'
    }
  },
  computed: {
    ...mapGetters('forms/entity', [
      'getField',
      'getForm'
    ]),
    formTitle: compField('title'),
    formCode: compField('code'),
    formPin: compField('pin'),
    formOrder: compField('order'),
    formFields: compField('fields'),
    fieldsOptions () {
      return this.formFields
        .map((field) => ({
          value: field.hash,
          name: field.title
        }))
    },
    title () {
      return 'Страницы'
    },
    dataTypes () {
      return namedListTypeField
    },
    orderFields: {
      get () {
        return this.formFields
      },
      set (v) {
        this.setOrderOfFields(v)
      }
    }
  },
  destroyed() {
    this.clear()
  },
  methods: {
    ...mapMutations('forms/entity', [
      'setField',
      'addCustomField',
      'removeCustomField',
      'editTitleCustomField',
      'editCodeCustomField',
      'editFieldCustomField',
      'setOrderOfFields',
      'setConfig',
      'setConfigFieldCustomFieldByField',
      'clear',
      'save'
    ]),
    ...mapActions('forms/entity', [
      'save'
    ]),
    saveHandler () {
      this.save()
        .then((entity) => {
          this.$emit('save', entity)
        })
    },
    getTypeField (name) {
      return this.dataTypes[name] || {}
    },
    setConfigMainField (value) {
      this.setConfig({
        mainField: value
      })
    },
    setConfigMulti (value) {
      this.setConfig({
        multi: value
      })
    },
  }
}
</script>

<style lang="less" scoped>
.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 16px;
  gap: 10px;

  &__item {

    &--full {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }

  &__fields {
    padding: 16px 16px 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 14px;
  }

  &__field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: @cBaseOne;
    padding: 10px 20px;
    border-radius: 4px;
    gap: 24px;

    &__nav {
      display: flex;
      justify-content: end;
      flex: 1;
      opacity: 0;
      align-items: center;
      gap: 6px;
      transition: 0.2s;
    }

    &--removed {
      opacity: 0.5;
    }

    &:hover &__nav {
      opacity: 1;
    }
  }
}
</style>
