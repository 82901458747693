<template>
  <div>
    <section v-if="getEntityData" class="p-16">
      <div class="d-flex w-100 justify-sb">
        <div class="entity__info">
          <h2>
            {{ getEntityData.title }}
          </h2>
          <div class="entity__details">
            Всего: {{ getCount }}
          </div>
        </div>
        <div class="entity__nav">
          <CircleBtn
            v-if="userHasRole(userRoles.ROLE_ADMIN)"
            icon="trash"
            type="error"
            @click="() => successRemoveEntity(getEntityData.hash)"
          />
          <RouterLink
            v-if="userHasRole(userRoles.ROLE_ADMIN)"
            :to="{ name: $routeNames.EDIT_ENTITY, params: { entity: getEntityData.code } }"
          >
            <CircleBtn icon="edit" />
          </RouterLink>
          <RouterLink :to="{ name: $routeNames.CREATE_NODE, params: { entity: getEntityData.code } }">
            <CircleBtn icon="plus" />
          </RouterLink>
        </div>
      </div>
    </section>

    <section class="p-16">
      <div class="rcms-table">
        <div class="rcms-table__header">
          <div v-for="field in columns" :key="field.hash" class="rcms-table__header__column">
            {{ field.title }}
          </div>
        </div>
        <div v-for="(node) in getNodes" :key="node.hash" class="rcms-table__row">
          <div v-for="field in columns" :key="field.hash" class="rcms-table__column">
            <MediaPreview
              v-if="field.type === 'media' && node.fields[field.hash]"
              :field="field"
              :media="node.fields[field.hash]"
            />
            <span v-else-if="field.type === 'relation'">
              {{ relationName(node, field) }}
            </span>
            <span v-else>
              {{ node.fields[field.hash] }}
            </span>
          </div>
          <div class="rcms-table__row__nav">
            <RouterLink :to="{ name: 'EDIT_NODE', params: { entity: $route.params.entity, node: node.hash } }">
              <CircleBtn icon="edit" />
            </RouterLink>
            <CircleBtn @click="() => removeNodeHandler({ hash: node.hash })" icon="trash" type="error"/>
          </div>
        </div>
        <div v-if="!getNodes || !getNodes.length">Ничего нет</div>
      </div>
    </section>
    <section class="p-16">
      <Pagination v-model="pagination" />
    </section>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import CircleBtn from "@/components/ui/CircleBtn";
import Pagination from "@/components/ui/Pagination";
import MediaPreview from "@/components/parts/MediaPreview";
import { helpModal, picturePreviewModal } from "@/mixins/modals";
import { user } from "@/mixins/user";

export default {
  name: 'EntityPage',
  components: {
    CircleBtn,
    Pagination,
    MediaPreview,
  },
  data () {
    return {
      v: true,
      paginationLimit: 10,
      paginationPage: 1,
    }
  },
  mixins: [picturePreviewModal, helpModal, user],
  computed: {
    ...mapGetters('entities', [
      'getEntityByCode',
      'getEntityByHash'
    ]),
    ...mapGetters('nodes', [
      'getCount',
      'getNodes',
      'getRelateNodes',
    ]),
    getEntityCode () {
      return this.$route.params.entity
    },
    title () {
      return 'Страницы'
    },
    getEntityData () {
      return this.getEntityByCode(this.$route.params.entity)
    },
    columns () {
      return this.getEntityData && Object.values(this.getEntityData.fields).filter(f => f.pin)
    },
    pagination: {
      get () {
        return {
          limit: this.paginationLimit,
          page: this.paginationPage,
          count: this.getCount
        }
      },
      set (v) {
        this.paginationPage = v.page
        this.paginationLimit = v.limit
        this.loadNodesHandler()
      }
    }
  },
  mounted() {
    this.loadNodesHandler()
  },
  methods: {
    ...mapActions('entities', [
      'removeEntity',
      'loadEntities'
    ]),
    ...mapActions('nodes', [
      'loadNodes',
      'removeNode'
    ]),
    ...mapMutations('forms/entity', [
      'setForm'
    ]),
    loadNodesHandler () {
      this.loadNodes({
        code: this.$route.params.entity,
        params: this.pagination
      })
    },
    removeNodeHandler (payload) {
      this.openHelpModal({
        title: 'Подтвердите действие',
        message: 'Вы уверены что хотите удалить запись?',
        buttons: [
          {
            type: 'danger',
            text: 'Удалить',
            click: () => this.removeNodeHandlerRun(payload),
            afterClick: 'emitClose'
          },
          {
            type: 'default',
            text: 'Отмена',
            click: 'emitClose'
          },
        ]
      })
    },
    removeNodeHandlerRun (payload) {
      this.removeNode(payload)
        .then(() => {
          this.loadNodesHandler()
        })
    },
    successRemoveEntity (id) {
      this.openHelpModal({
        title: 'Подтвердите действие',
        message: 'Вы уверены что хотите удалить сущность?',
        buttons: [
          {
            type: 'danger',
            text: 'Удалить',
            click: () => this.removeEntityHandler(id),
            afterClick: 'emitClose'
          },
          {
            type: 'default',
            text: 'Отмена',
            click: 'emitClose'
          },
        ]
      })
    },
    removeEntityHandler (id) {
      this.removeEntity(id)
        .then(() => {
          this.loadEntities({ force: true })
          this.$router.push({ name: this.$routeNames.HOME })
        })
    },
    relationName (node, field) {
      if (!node || !field) {
        return ''
      }
      const value = node.fields[field.hash]

      if (!value || !(field.config && field.config.entityHash)) {
        return ''
      }
      const relateEntity = this.getEntityByHash(field.config.entityHash)
      if (!relateEntity) {
        return ''
      }
      const relateNodes = value.map(hash => this.getRelateNodes[hash])
      if (!relateNodes) {
        return ''
      }
      return relateNodes
        .filter((rn => rn))
        .map(rn => rn.fields[relateEntity.config.mainField]).join(', ')
    }
  },
  watch: {
    getEntityCode: {
      handler () {
        this.loadNodesHandler()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.entity {

  &__header {
    display: flex;
    justify-content: space-between;
    font-family: @ffOne;
  }

  &__title {
    font-size: 30px;
    font-weight: 700;
    color: @cBaseThree;
  }

  &__details {
    padding-top: 4px;
    font-size: 16px;
    font-weight: 700;
    font-family: @ffTwo;
  }

  &__nav {
    display: flex;
    gap: 6px;
  }
}
</style>
